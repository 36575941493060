import React from "react";

const Contact = () => {
  return (
    <div
      id="contact"
      style={
        {
          // marginTop: 0,
          // marginBottom: 0,
        }
      }
    >
      <h3>Secura365 ©</h3>
    </div>
  );
};

export default Contact;
